import { faker } from '@faker-js/faker';

const products = [...Array(20)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    imgSrc: `/static/pics/${setIndex}.png`,
  };
});

export default products;
