import { useCallback } from 'react';
import { Box, Button, Card, CardHeader, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { faker } from '@faker-js/faker';
import PlayArrow from '@mui/icons-material/PlayArrow';
import ArrowBullet from '@mui/icons-material/NavigateNext';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

export default function Register() {
  const openWaiver = useCallback(() => {
    window.open(
      'https://form.jotform.com/222431269431147',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  const openRegistration = useCallback(() => {
    window.open(
      'https://form.jotform.com/243575434683162',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  const openJersey = useCallback(() => {
    window.open(
      'https://form.jotform.com/243575555315158',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  const guestDetails = [
    'Includes Intersquad & External Games only',
    'No Fitness & Practice Sessions',
    '$25 Monthly Fees',    
    'Payment Options:'
  ];
  const guestPaymentOptions = [
    'Quarterly: $75',
    'Half Yearly: $140 ($10 discount)',
    'Yearly: $275 ($25 discount)',
    ];
  const recDetails = [
    'Includes All Practice Sessions & Intersquad Games',
    'No Fitness & External Games',
    '$50 Monthly Fees (Siblings: $40)',    
    'Payment Options:'
  ];
  const recPaymentOptions = [
    'Quarterly: $150 / Siblings: $120',
    'Half Yearly: $275 / Siblings: $215 ($25 discount)',
    'Yearly: $550 / Siblings: $430 ($50 discount)',
  ];
  const recPlusDetails = [
    'Includes All Practice Sessions, Intersquad Games & Fitness Sessions',
    'No External Games',
    '$65 Monthly Fees (Siblings: $55)',    
    'Payment Options:'
  ];
  const recPlusPaymentOptions = [
    'Quarterly: $195 / Siblings: $165',
    'Half Yearly: $360 / Siblings: $300 ($30 discount)',
    'Yearly: $715 / Siblings: $605 ($65 discount)',
  ];
  const proDetails = [
    'Includes All Practice Sessions, Intersquad/External Games & Fitness Sessions',    
    '$75 Monthly Fees (Siblings: $65)',    
    'Payment Options:'
  ];
  const proPaymentOptions = [
    'Quarterly: $225 / Siblings: $195',
    'Half Yearly: $415 ($35 discount) / Siblings: $355',
    'Yearly: $825 ($75 discount) / Siblings: $705',
  ];
  

  return (
    <Page title="Register">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Registration
          </Typography>
        </Stack>
        <Card>
          <CardHeader title="Please complete this registration form for each child (includes waiver):" />
          <Stack direction="row" alignItems="center" spacing={2}>            
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
              <Button
                variant="outlined"
                onClick={openRegistration}
                startIcon={<Iconify icon="clarity:form-line" width={20} height={20} />}
              >
                Registration
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between"  mt={5} mb={1}>
          <Typography variant="h4" gutterBottom>
            Jersey
          </Typography>
        </Stack>
        <Card>
          <CardHeader title="Please enter jersey related details for each child: " />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
              <Button
                variant="outlined"
                onClick={openJersey}
                startIcon={<Iconify icon="clarity:form-line" width={20} height={20} />}
              >
                Jersey Form
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between"  mt={5} mb={1}>
          <Typography variant="h4" gutterBottom>
            Programs Offered & Fee Structure (Effective Jan 1st, 2025)
          </Typography>
          </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between"  mt={1} mb={1}>
          <Typography variant="h6" gutterBottom>
            All programs have an annual registration fees of $125 (Siblings: $100)
          </Typography>
        </Stack>
        <Card>
        <CardHeader title={'Guests'} titleTypographyProps={{variant:'h4' }}/>
          {guestDetails.map((details) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 20px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary={details} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
          
          {guestPaymentOptions.map((payment) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 50px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <ArrowBullet />
                  </ListItemIcon>
                  <ListItemText primary={payment} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}

        <CardHeader title={'Recreational'} titleTypographyProps={{variant:'h4' }}/>
          {recDetails.map((details) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 20px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary={details} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
          
          {recPaymentOptions.map((payment) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 50px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <ArrowBullet />
                  </ListItemIcon>
                  <ListItemText primary={payment} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}

        <CardHeader title={'Recreational Plus'} titleTypographyProps={{variant:'h4' }}/>
          {recPlusDetails.map((details) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 20px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary={details} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
          
          {recPlusPaymentOptions.map((payment) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 50px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <ArrowBullet />
                  </ListItemIcon>
                  <ListItemText primary={payment} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
        <CardHeader title={'Pro Level'} titleTypographyProps={{variant:'h4' }}/>
          {proDetails.map((details) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 20px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <PlayArrow />
                  </ListItemIcon>
                  <ListItemText primary={details} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
          
          {proPaymentOptions.map((payment) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton sx={{ padding: '0px 0px 0px 50px' }}>
                  <ListItemIcon sx={{minWidth:'30px'}}>
                    <ArrowBullet />
                  </ListItemIcon>
                  <ListItemText primary={payment} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
          
          <CardHeader title={'Payment via zelle: scvcricketacademy@gmail.com'} subheader={'All programs have an annual registration fees of $125 (Siblings: $100)'}/>
          <br/>          
        </Card>
      </Container>
    </Page>
  );
}
